import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import { OrangeLayout, LinkTo } from '../../components'
import config from '../../../config'

const Category = ({
  data: {
    allMdx: { group },
  },
}) => (
  <OrangeLayout title="Categories">
    <Helmet title={`Categories | ${config.siteTitle}`} />
    {group.map(cat =><>
      <LinkTo.Category name={cat.fieldValue}/> ({cat.totalCount}) <br/>
    </>)}
  </OrangeLayout>
)

export default Category

Category.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      group: PropTypes.array.isRequired,
    }),
  }).isRequired,
}

export const postQuery = graphql`
  query CategoriesPage {
    allMdx {
      group(field: frontmatter___categories) {
        fieldValue
        totalCount
      }
    }
  }
`
